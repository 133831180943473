
import { updateAppConfig } from '#app'
import { defuFn } from '/usr/local/app/node_modules/.pnpm/defu@6.1.2/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "storefront": {
    "name": "Vuci"
  },
  "nuxt": {
    "buildId": "629f7cd3-58f0-4992-8611-2f0b522e6e47"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/usr/local/app/app.config.ts"

export default /* #__PURE__ */ defuFn(cfg0, inlineConfig)
